import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`When you set up the `}<strong parentName="p">{`Config`}</strong>{` for your site, you’ll want to include `}<inlineCode parentName="p">{`gatsby-transformer-sharp`}</inlineCode>{` and `}<inlineCode parentName="p">{`gatsby-plugin-sharp`}</inlineCode>{`.`}</p>
    <p>{`You’ll also need to configure `}<inlineCode parentName="p">{`gatsby-source-filesystem`}</inlineCode>{` with the appropriate options. The `}<inlineCode parentName="p">{`path`}</inlineCode>{` should be the path to the directory your image files live in.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      