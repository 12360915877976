import React from "react"

const AbstractSymbol = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    focusable="false"
  >
    <circle
      cx="16.703"
      cy="16.777"
      r="6.364"
      transform="rotate(45 16.703 16.777)"
      fill="currentColor"
    />
    <circle
      cx="16.703"
      cy="2.522"
      r="1.018"
      transform="rotate(90 16.703 2.522)"
      fill="currentColor"
    />
    <circle
      cx="2.449"
      cy="16.776"
      r="1.018"
      transform="rotate(90 2.449 16.776)"
      fill="currentColor"
    />
    <circle
      cx="6.624"
      cy="6.697"
      r="1.018"
      transform="rotate(45 6.624 6.697)"
      fill="currentColor"
    />
    <circle
      cx="6.624"
      cy="26.856"
      r="1.018"
      transform="rotate(45 6.624 26.856)"
      fill="currentColor"
    />
    <path
      d="M16.703 2.522c7.873 0 14.255 6.382 14.255 14.255 0 7.872-6.382 14.254-14.255 14.254a14.206 14.206 0 01-9.8-3.903"
      stroke="currentColor"
    />
  </svg>
)

export default AbstractSymbol
