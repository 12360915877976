import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Now you can use JSX to add the processed image to your page. You need to import `}<inlineCode parentName="p">{`Img`}</inlineCode>{` from `}<inlineCode parentName="p">{`gatsby-image`}</inlineCode>{`, the package you installed at the beginning.`}</p>
    <p>{`Using the `}<inlineCode parentName="p">{`Img`}</inlineCode>{` tag you’ll pass in the object returned from your GraphQL query. The key `}<inlineCode parentName="p">{`fluid`}</inlineCode>{` matches the way the image was processed. It needs to match. And always include an alt tag with a description of your image.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      