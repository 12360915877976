import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Using images in Gatsby requires a few different plugins.`}</p>
    <p><strong parentName="p">{`Install`}</strong>{` `}<inlineCode parentName="p">{`gatsby-transformer-sharp`}</inlineCode>{` and `}<inlineCode parentName="p">{`gatsby-plugin-sharp`}</inlineCode>{` to transform and process your images. `}<inlineCode parentName="p">{`gatsby-image`}</inlineCode>{` is a package that includes a component you’ll use later in “View”.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      