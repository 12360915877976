import React from "react"

const AtomicSymbol = () => (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    focusable="false"
  >
    <path d="M30.296 12.356a74.352 74.352 0 01-9.638 11.549C12.243 32.32 3.9 37.622 2.022 35.74c-1.336-1.336 1.024-6.002 5.499-11.583M20.54 11.049a54.897 54.897 0 014.887-3.602" />
    <path d="M16.12 11.244C10.442 6.662 5.67 4.22 4.317 5.566-.608 10.49 29.443 39.647 33.34 35.74c1.158-1.159-.418-4.783-3.777-9.368M12.028 13.16a11.455 11.455 0 11-4.567 7.822M32.554 8.653a3.46 3.46 0 100-6.918 3.46 3.46 0 000 6.918z" />
  </svg>
)

export default AtomicSymbol
