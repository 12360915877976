import React from "react"

const GraphQLLogo = () => (
  <svg
    width="33"
    height="38"
    viewBox="0 0 33 38"
    fill="currentColor"
    stroke="currentColor"
    focusable="false"
  >
    <path d="M17.892 33.372l-.013-.023 13.673-7.894.014.022zM3.051 27.56l-.023-.013 14.227-24.64.023.013L3.05 27.56z" />
    <path d="M2.44 26.516h28.453v.027H2.44v-.027z" />
    <path d="M16.986 34.24L3.312 26.347l.014-.023 13.673 7.895-.013.023zm13.028-22.565L16.34 3.781l.013-.023 13.674 7.894-.014.023z" />
    <path d="M3.33 11.67l-.014-.024L16.99 3.752l.013.023L3.33 11.669z" />
    <path d="M30.296 27.561L16.07 2.921l.023-.014 14.227 24.64-.023.014zM3.629 11.1h.026v15.79H3.63V11.1z" />
    <path d="M29.688 11.1h.026v15.79h-.026V11.1z" />
    <path d="M31.876 27.774l-.001.003a2.508 2.508 0 01-3.423.917 2.508 2.508 0 01-.916-3.422 2.508 2.508 0 013.421-.917 2.494 2.494 0 01.92 3.42zM5.797 12.717a2.508 2.508 0 01-3.422.917 2.508 2.508 0 01-.916-3.423 2.508 2.508 0 013.42-.917 2.51 2.51 0 01.918 3.423zm-4.329 15.06a2.51 2.51 0 01.918-3.423 2.51 2.51 0 013.422.92 2.51 2.51 0 01-.919 3.42 2.506 2.506 0 01-3.421-.917zm26.077-15.06a2.51 2.51 0 01.918-3.423 2.51 2.51 0 013.422.919 2.51 2.51 0 01-.919 3.421 2.508 2.508 0 01-3.42-.917zM16.671 36.558a2.5 2.5 0 01-2.503-2.503 2.5 2.5 0 012.503-2.503 2.5 2.5 0 012.503 2.503 2.506 2.506 0 01-2.503 2.503zm0-30.121a2.5 2.5 0 01-2.503-2.503 2.5 2.5 0 012.503-2.503 2.5 2.5 0 012.503 2.503 2.5 2.5 0 01-2.503 2.503z" />
  </svg>
)

export default GraphQLLogo
